/*@font-face {
  font-family: "Sailec Medium";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sailec Black";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec Black.ttf') format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Sailec Bold";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sailec Light";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sailec Thin";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec Thin.ttf') format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sailec Hairline";
  src: local("Sailec"),
  url('./fonts/Type Dynamic - Sailec Hairline.ttf') format("truetype");
  font-weight: 100;
  font-style: normal;
}
*/
@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Black.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

#cover-right-widget {
  margin: 0;
  font-family: 'Sailec', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  p {
    font-family: 'Sailec', 'Roboto', 'Helvetica Neue', sans-serif;
  }
  input {
    border-style: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.load{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width:100px;
  height:100px;
}
.load hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.load :first-child{background:#5ECB9E;animation-delay:-1.5s}
.load :nth-child(2){background:#029094;animation-delay:-1s}
.load :nth-child(3){background:#5ECB9E;animation-delay:-0.5s}
.load :last-child{background:#029094}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}

.question-enter {
  opacity: 0;
  transform: translate3d(0, 30%, 0);
}
.question-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms, transform 300ms;
}
.question-exit {
  opacity: 1;
}
.question-exit-active {
  opacity: 0;
  transform: translate3d(0, -30%, 0);
  transition: opacity 300ms, transform 300ms;
}

.activeMenu > div {
  color: rgba(2, 144, 148, 1);
  background: rgba(2, 144, 148, 0.05);
}

ul ul {
  list-style-type: none;
  padding-inline-start: 18px;
  padding-top: 5px;
  & > li {
    text-indent: -17px;
  }
  & > li:before {
    content: "\2014 ";
    margin-right: 5px;
  }
}

.reactour__helper {
  outline: none;
}

div.success-snack {
  background-color: #029094!important;
}

div.list-item {
  display: list-item;
  margin-left: 14px;
}
